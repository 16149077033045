<template>
  <div>
        <div class="row" v-if="hideSearch === 0 || hideLocationSearch === 0">

              <div :class="'col-md-' + splitLeft + ' col-xs-12'" v-if="hideSearch === 0">
                <search-nijmegen/>
              </div>
              <div class="col-md-4 col-xs-12" v-if="hideLocationSearch === 0">
                <search-location-nijmegen/>
              </div>
              <div class="col-md-4 col-xs-12">
                <button type="button" class="btn btn-info w-100 mt-0" @click="toggle" style="margin-top:25px !important;">
                  <template v-if="!fullscreen">
                    <span class="mdi mdi-arrow-expand-all"></span> Volledig scherm
                  </template>
                  <template  v-if="fullscreen">
                    <span class="mdi mdi-arrow-collapse-all"></span> Volledig scherm sluiten
                  </template>
                </button>
              </div>
        </div>
        <div class="row justify-content-md-center">
            <div class="col-md-auto">
                <switch-nijmegen v-if="layoutCombi !== 1"/>
            </div>
        </div>
        <div class="row text-left mt-4">
            <template v-if="layoutCombi === 1">
              <template v-show="splitLeft > 0">
                <list-nijmegen :class="'col-md-' + splitLeft + ' col-xs-12'" v-show="activeTab === 'list' && activeSubTab !== 'detail'"/>
                <detail-view :class="'col-md-' + splitLeft + ' col-xs-12'" v-if="activeTab === 'list' && activeSubTab === 'detail'"/>
              </template>
              <template v-show="splitRight > 0">
                <template v-if="mounted === 1">
                  <map-view :class="'col-md-' + splitRight + ' col-xs-12'"/>
                </template>
              </template>
              <filter-nijmegen v-show="false"/>
            </template>
            <template v-else>
              <div :class="'col-md-' + splitLeft + ' col-xs-12'">
                  <filter-nijmegen/>
              </div>
              <div :class="'col-md-' + splitRight + ' col-xs-12'">
                    <list-nijmegen v-show="activeTab === 'list' && activeSubTab !== 'detail'"/>
                    <detail-view v-if="activeTab === 'list'  && activeSubTab === 'detail'"/>
                    <template v-if="isMapMounted === true">
                      <map-view v-show="activeTab === 'map'"/>
                    </template>
              </div>
            </template>
        </div>
        <features/>
  </div>
</template>

<script>

import Features from "@/components/features/Features";
import MapView from "@/views/MapView";
import DetailView from "@/views/DetailView";
import SwitchNijmegen from "@/layout/Nijmegen/SwitchNijmegen";
import ListNijmegen from "@/layout/Nijmegen/ListNijmegen";
import SearchNijmegen from "@/layout/Nijmegen/SearchNijmegen";
import SearchLocationNijmegen from "@/layout/Nijmegen/SearchLocationNijmegen";
import FilterNijmegen from "@/layout/Nijmegen/FilterNijmegen";

export default {
    name: 'LayoutNijmegen',
    data() {
      return {
         filterClass: 'd-none',
         listClass: '12',
         mounted: 1,
         // fullscreen: false
      };
    },
    components: {
        FilterNijmegen,
        SearchNijmegen,
        SearchLocationNijmegen,
        ListNijmegen,
        Features,
        MapView,
        DetailView,
        SwitchNijmegen
    },
    created() {
      if(!this.activeFeatureID) {
        if (this.mapFirst === 1) {
          this.$store.commit('setActiveTab', 'map')
          this.$store.commit('isMapMounted', true);
        } else {
          this.$store.commit('setActiveTab', 'list')
        }
      }
    },
    watch: {
       filterSettings(filterSettings) {
         if(filterSettings === null) {
           this.filterClass = 'd-none';
           this.listClass = this.splitLeft
         } else {
           this.filterClass = this.splitLeft;
           this.listClass = this.splitRight;
         }
       },
       features(features) {
         if(this.activeFeatureID) {
           const feature = features.find(feature => feature.id === this.activeFeatureID)
           if(feature) {
             this.$store.commit('setActiveTab', 'list')
             this.$store.commit('setActiveSubTab', 'detail')
             this.$store.commit('setActiveFeature', feature);
           } else {
             if (this.mapFirst === 1) {
               this.$store.commit('setActiveTab', 'map')
               this.$store.commit('isMapMounted', true);
             } else {
               this.$store.commit('setActiveTab', 'list')
             }
           }
         }
       }
    },
    computed: {
        activeTab() {
            return this.$store.getters.getActiveTab
        },
        hideSearch() {
            return this.$store.getters.getHideSearch
        },
        hideLocationSearch() {
            return this.$store.getters.getHideLocationSearch
        },
        activeSubTab() {
            return this.$store.getters.getActiveSubTab
        },
        splitLeft() {
            return this.$store.getters.getSplitLeft
        },
        splitRight() {
            return this.$store.getters.getSplitRight
        },
        filterSettings() {
           return this.$store.getters.getFilter;
        },
        layoutCombi() {
           return this.$store.getters.getLayoutCombi;
        },
        isMapMounted() {
           return this.$store.getters.isMapMounted;
        },
        fullscreen() {
           return this.$store.getters.getFullScreen;
        },
        mapFirst() {
          return this.$store.getters.getMapFirst
        },
        activeFeatureID() {
          return this.$store.getters.getActiveFeatureID;
        },
        features() {
          return this.$store.getters.getFeatures
        },
    },
    mounted() {
        // setTimeout(() => {
          this.mounted = 1
        // }, 300);
    },
    methods: {
      toggle () {
        this.$store.commit('setFullScreen', !this.fullscreen)
      }
    }
}
</script>

<style>
  .fullscreen {
    background: #ffffff !important;
    padding:50px;
  }
  /*.autocomplete__clear-button, .autocomplete__search-button {*/
  /*  margin-top:-35px !important;*/
  /*  top: unset !important;;*/
  /*}*/

  @media only screen and (min-width: 768px) {
    #pills_list {
      margin-top: -22px;
      margin-bottom: -10px;
    }
  }

  .leaflet-container a.leaflet-popup-close-button {
    font-size: 20px !important;
    padding-top:10px !important;
    margin-right: 10px;
  }
</style>
