import UrlMethodsMixin from "@/mixins/UrlMethodsMixin";

export default {
    mixins: [
        UrlMethodsMixin
    ],
    data() {
        return {
            itemsPerPage: 5,
            pageNumbers: [],
            showPageNumbers: false
        }
    },
    computed: {
        filteredFeatures() {


            const filteredFeatures = this.$store.getters.getFilteredFeatures


            this.updatePageNumbers(filteredFeatures)

            return filteredFeatures
        },
        listSettings() {
            console.log(this.$store.getters.getList);
            return this.$store.getters.getList
        },
        detailSettings() {
            // //console.log(this.$store.getters.getList);
            return this.$store.getters.getDetail
        },
        searchValue() {
            return this.$store.getters.getSearchValue
        },
        activePage() {
            return this.$store.getters.getActivePage
        },
        features() {
            return this.$store.getters.getFeatures
        },
        isFeatureFound() {
            return this.$store.getters.isFeatureFound
        }
    },
    methods: {
        setActiveTab(e, activeTab, feature, activeSubTab = null) {
            e.preventDefault();
            this.activeTab = activeTab;
            this.$store.commit('setActiveTab', activeTab);
            if(activeSubTab !== null) {
                this.$store.commit('setActiveSubTab', activeSubTab);
            }

            console.log('settt')

            this.$store.commit('setActiveFeature', feature);
        },
        totalPageNumbers() {
            return Math.ceil(this.filteredFeatures.length / this.itemsPerPage);
        },
        updatePageNumbers() {
            this.pageNumbers = [];

            const activePage = this.activePage
            if (activePage === 0) {
                this.pageNumbers.push(activePage + 1);
                this.pageNumbers.push(activePage + 2);
                this.pageNumbers.push(activePage + 3);
                this.pageNumbers.push(activePage + 4);
                this.pageNumbers.push(activePage + 5);
            } else if (this.activePage === 1) {
                this.pageNumbers.push(activePage);
                this.pageNumbers.push(activePage + 1);
                this.pageNumbers.push(activePage + 2);
                this.pageNumbers.push(activePage + 3);
                this.pageNumbers.push(activePage + 4);
            } else {
                this.pageNumbers.push(activePage - 1);
                this.pageNumbers.push(activePage);
                this.pageNumbers.push(activePage + 1);
                this.pageNumbers.push(activePage + 2);
                this.pageNumbers.push(activePage + 3);
            }
        }
    },
}
