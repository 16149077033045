<template>
  <div>

    <button type="button"
            style="text-align: left;
            padding-left: 0;
            margin-left: -4px;"
            class="btn btn-info w-100 mb-0"
            v-on:click="setActiveTab($event, 'list')">
      <span class="mdi mdi-arrow-left" aria-hidden="true"></span>
      Terug naar zoekresultaten
    </button>

    <ol class="breadcrumb" v-if="breadCrumbs.length >= 2">
      <li v-for="(breadCrumb, index) in breadCrumbs" :class="'breadcrumb-item ' + isBreadCrumActive(index)"  v-bind:key="breadCrumb.ID">
        <template v-if="index === ( breadCrumbs.length - 1)">
          {{breadCrumb.FormName}}
        </template>
        <template v-else>
          <a href="#" title="Home" v-on:click="goToDetailPage($event, index)">
            {{breadCrumb.FormName}}
          </a>
        </template>
      </li>
    </ol>

    <template v-if="breadCrumbs.length === 1">
        <div v-for="form in forms" v-bind:key="form.ID">
          <template v-if="form.detailSettings !== undefined && form.presentationID === form.detailSettings.PresentationID">
            <h2 class="mt-2" v-html="form.detailSettings.FormName"></h2>
            <template v-if="form.detailSettings.FormType === 3">
              <div class="" v-for="setting in form.detailSettings.records[0].attributes" :key="setting[0].ID">
                  <template>
                    <template v-if="parseInt(form.detailSettings.ShowEmptyFormAttributes) === 1 ||
                          activeFeature.properties[setting[0].Attribute] !== null && activeFeature.properties[setting[0].Attribute] !== ''">
                        <template v-for="settingItem in setting">
                          <div v-bind:key="settingItem.ID">
                            <template v-if="parseInt(settingItem.Type) === 0">
                              <h2>{{ settingItem.DisplayName }}</h2>
                              <p>{{settingItem.Prefix}}{{ activeFeature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}</p>
                            </template>
                            <template v-else-if="parseInt(settingItem.Type) === 1">
                              <h2>{{ settingItem.DisplayName }}</h2>
                              <div class="row">
                                <div class="col-12">
                                  <figure @click="() => showImg(index)">
                                    <a :aria-label="'Bekijk vergrote afbeelding van een ' + activeFeature.properties[settingItem.Attribute]"  @click="clickImage" :href="getImageUrl(settingItem, activeFeature)" data-size="1600x1067">
                                      <img class="img-fluid" :src="getImageUrl(settingItem, activeFeature)" :alt="'' + activeFeature.properties[settingItem.Attribute]"/>
                                    </a>
                                  </figure>
                                </div>
                              </div>
                            </template>
                            <template v-if="parseInt(settingItem.Type) === 3">
                              <h2>
                                {{ settingItem.DisplayName }}
                              </h2>
                              <p>
                                <a aria-label="Open link in nieuw tabblad" target="_blank" :href="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix">
                                  {{settingItem.Prefix}}{{ activeFeature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}
                                </a>
                              </p>
                            </template>
                            <template v-if="parseInt(settingItem.Type) === 402">
                              <h2>{{ settingItem.DisplayName }}</h2>
                              <p>
                                {{activeFeature.properties[settingItem.Attribute]}}
                              </p>
                            </template>
                          </div>
                        </template>
                    </template>
                  </template>
                </div>
            </template>
            <template v-else>
              <table class="responsive-table">
                <tbody>
                <template class="" v-for="setting in form.detailSettings.records[0].attributes">
                    <template v-if="parseInt(form.detailSettings.ShowEmptyFormAttributes) === 1 ||
                             activeFeature.properties[setting[0].Attribute] !== null && activeFeature.properties[setting[0].Attribute] !== ''">
                      <tr v-bind:key="setting[0].ID">
                        <th class="d-none d-md-table-cell">
                          <template v-if="setting[0].Type === 21">
                             Dichtstbijzijnde adres
                          </template>
                          <template v-else>
                            {{setting[0].DisplayName}}
                          </template>
                        </th>
                        <td :data-title="setting[0].DisplayName">
                            <template v-for="settingItem in setting">
                                <template v-if="settingItem.Type === 21">
                                  {{closestAdress}}
                                </template>
                                  <template v-if="parseInt(setting[0].Type) === 0">
                                      {{settingItem.Prefix}}{{ activeFeature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}
                                  </template>
                                  <template v-else-if="parseInt(setting[0].Type) === 1">
                                    <template v-if="isImage(settingItem.Suffix, activeFeature.properties[settingItem.Attribute])">
                                      <div class="row" v-bind:key="settingItem.ID">
                                        <img class="col-md-4" style="display: block" :src="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + form.detailSettings.DomainID + '/presentation/' + form.detailSettings.PresentationID + '/formattribute/' + settingItem.FormAttributeID + '/item/0/filename/.jpg?fileName=' + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix" :title="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix">
                                      </div>
                                    </template>
                                    <a aria-label="Open link in nieuw tabblad" v-bind:key="settingItem.ID" v-if="!isImage(settingItem.Suffix, activeFeature.properties[settingItem.Attribute])" class="open_link" :href="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + form.detailSettings.DomainID + '/presentation/' + form.detailSettings.PresentationID + '/formattribute/' + settingItem.FormAttributeID + '/item/0/filename/' + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix + '?fileName=' + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix" target="_blank">
                                      Open link
                                    </a>
                                  </template>
                                  <template v-else-if="parseInt(setting[0].Type) === 3">
                                    <template v-for="settingItem in setting">
                                      <a aria-label="Open link in nieuw tabblad" target="_blank" v-bind:key="settingItem.ID" :href="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix">
                                        <template v-if="settingItem.Action === 1">
                                          {{settingItem.Prefix}}{{ activeFeature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}
                                        </template>
                                        <template v-else>
                                          <template v-if="settingItem.ActionAdvancedSecond">
                                            {{settingItem.ActionAdvancedSecond}}
                                          </template>
                                          <template v-else>
                                            Open link
                                          </template>
                                        </template>
                                      </a>
                                    </template>
                                  </template>
                                  <template v-if="parseInt(setting[0].Type) === 21">
                                    <template v-if="activeFeature.properties.kv.geometry !== undefined">
                                      {{ (activeFeature.properties.kv.geometry.value / 1000).toFixed(1) }} KM
                                    </template>
                                  </template>
                                  <template v-else-if="parseInt(setting[0].Type) === 402">
                                    {{settingItem.Prefix}}{{ activeFeature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}
                                  </template>
                            </template>
                        </td>
                      </tr>
                    </template>
                </template>
                </tbody>
              </table>
            </template>

            </template>
            <template v-if="presentationLinkedLoaded">
              <template v-for="presentationLinked in presentationsLinked">
                <template v-if="form.presentationID === presentationLinked.PresentationID">
                    <template v-if="parseInt(presentationLinked.attributes[0][0].Type) === 1">
                      <h2 class="mt-2" v-bind:key="presentationLinked.FormID" v-show="presentationLinked.features.length > 0">
                        {{ presentationLinked.FormName }}
                      </h2>
                      <div class="mdb-lightbox row" style="margin-left:-8px" v-bind:key="'body-' + presentationLinked.FormID">
                        <figure
                            v-for="(feature, index) in presentationLinked.features"
                            :key="index"
                            class="pic col-md-4"
                            @click="() => showImg(index)"
                        >
                          <template v-if="feature.properties[presentationLinked.attributes[0][1]] !== undefined && feature.properties[presentationLinked.attributes[0][1]] === null">
                            <a :aria-label="'Bekijk vergrote afbeelding van een ' + feature.properties[presentationLinked.attributes[0][1].Attribute]"  @click="clickImage" :href="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + presentationLinked.DomainID + '/presentation/' + presentationLinked.PresentationID + '/formattribute/' + presentationLinked.attributes[0][0].FormAttributeID + '/item/' + index + '/filename/.jpg?fileName=' + feature.properties[presentationLinked.attributes[0][0].Attribute] + '&dt=' + new Date().getTime()" data-size="1600x1067">
                              <img class="img-fluid" :src="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + presentationLinked.DomainID + '/presentation/' + presentationLinked.PresentationID + '/formattribute/' + presentationLinked.attributes[0][0].FormAttributeID + '/item/' + index + '/filename/.jpg?fileName=' + feature.properties[presentationLinked.attributes[0][0].Attribute] + '&dt=' + new Date().getTime()" :alt="feature.properties[presentationLinked.attributes[0][1].Attribute]"/>
                             </a>
                          </template>
                          <template v-else>
                            <a @click="clickImage" :href="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + presentationLinked.DomainID + '/presentation/' + presentationLinked.PresentationID + '/formattribute/' + presentationLinked.attributes[0][0].FormAttributeID + '/item/' + index + '/filename/.jpg?fileName=' + feature.properties[presentationLinked.attributes[0][0].Attribute] + '&dt=' + new Date().getTime()"  :aria-label="'Bekijk vergrote afbeelding van een ' + feature.properties[presentationLinked.attributes[0][1].Attribute]">
                              <img class="img-fluid" :src="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + presentationLinked.DomainID + '/presentation/' + presentationLinked.PresentationID + '/formattribute/' + presentationLinked.attributes[0][0].FormAttributeID + '/item/' + index + '/filename/.jpg?fileName=' + feature.properties[presentationLinked.attributes[0][0].Attribute] + '&dt=' + new Date().getTime()" :alt="feature.properties[presentationLinked.attributes[0][1].Attribute]"/>
                            </a>
                          </template>
                        </figure>
                      </div>
                    </template>
                    <template v-else>
                      <h2 class="mt-2" v-html="presentationLinked.FormName" v-bind:key="presentationLinked.FormID"></h2>
                      <table class="responsive-table" v-bind:key="presentationLinked.FormID">
                        <thead>
                          <tr>
                              <th v-for="attributes in presentationLinked.attributes" v-bind:key="attributes.ID">
                                  <span>{{attributes[0].DisplayName}}</span>
                              </th>
                              <th v-if="parseInt(presentationLinked.IsShowMoreInfoVisible) === 1">
                                   Links naar meer informatie
                              </th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr v-for="feature in presentationLinked.features" v-bind:key="feature.id">
                              <td :data-title="attributes[0].DisplayName" v-for="attributes in presentationLinked.attributes" v-bind:key="attributes.ID">
                                <template v-for="attribute in attributes">
                                  <div v-bind:key="attribute.ID">
                                    <template v-if="parseInt(attribute.Type) === 0">
                                      {{attribute.Prefix}}{{ feature.properties[attribute.Attribute] }}{{attribute.Suffix}}
                                    </template>
                                    <template v-else-if="parseInt(attribute.Type) === 1">
                                      <!--                <img :src="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix" :title="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix">-->
                                      <template v-if="isImage(attribute.Suffix, feature.properties[attribute.Attribute])">
                                        <div class="row" v-bind:key="attribute.ID" >
                                          <img class="col-md-4" style="display: block" :src="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + detailSettings.DomainID + '/presentation/' + detailSettings.PresentationID + '/formattribute/' + attribute.FormAttributeID + '/item/0/filename/.jpg?fileName=' + feature.properties[attribute.Attribute] + attribute.Suffix" :title="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix">
                                        </div>
                                      </template>
                                      <a aria-label="Open link in nieuw tabblad" v-bind:key="attribute.ID" v-if="!isImage(attribute.Suffix, feature.properties[attribute.Attribute])" class="open_link" :href="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + detailSettings.DomainID + '/presentation/' + detailSettings.PresentationID + '/formattribute/' + attribute.FormAttributeID + '/item/0/filename/file?fileName=' + feature.properties[attribute.Attribute] + attribute.Suffix" target="_blank">
                                        <template v-if="attribute.Action === 1">
                                          {{feature.properties[attribute.Attribute] + attribute.Suffix}}
                                        </template>
                                        <template v-else>
                                          Open link
                                        </template>
                                      </a>
                                    </template>
                                    <template v-else-if="parseInt(attribute.Type) === 3">
                                      <a aria-label="Open link in nieuw tabblad" v-on:click="openLink(attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix)" target="_blank" :href="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix">{{ feature.properties[attribute.Attribute] }}{{attribute.Suffix}}</a>
                                    </template>
                                    <template v-else-if="parseInt(attribute.Type) === 402">
                                      <div v-html="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix"></div>
                                    </template>
                                    <template v-else-if="parseInt(attribute.Type) === 404">
                                      <div v-html="attribute.Prefix + feature.properties[attribute.Attribute] + attribute.Suffix"></div>
                                    </template>
                                  </div>
                                </template>
                              </td>
                              <td v-if="parseInt(presentationLinked.IsShowMoreInfoVisible) === 1">
                                <a href="#"
                                   :aria-label="generateAriaLabel(feature, presentationLinked.attributes[0])"
                                   v-on:click="loadLinkedPresentation($event, presentationLinked, feature)">
                                  Lees meer
                                </a>
                              </td>
                            </tr>
                            <tr v-if="presentationLinked.features.length === 0">
                                <td :colspan="presentationLinked.attributes.length" class="d-none d-sm-table-cell">
                                  Geen {{ presentationLinked.FormName.toLowerCase() }} gevonden
                                </td>
                                <td :data-title="'Geen ' + presentationLinked.FormName.toLowerCase() + ' gevonden'" :colspan="presentationLinked.attributes.length" class="d-block d-sm-none">
                                </td>
                            </tr>
                        </tbody>
                      </table>
                    </template>
                </template>
              </template>
            </template>
      </div>
    </template>
    <template v-if="breadCrumbs.length > 1">
      <template v-if="!presentationLinkedLoaded">
        <h2>
          Bezig met ophalen van de gegevens...
        </h2>
      </template>
      <template v-else>
          <template v-if="breadCrumbs[breadCrumbActiveIndex].featuresLinked.length === 0">
            <h2>
              Er zijn geen gegevens gevonden...
            </h2>
          </template>
          <template v-else>
              <template v-for="feature in breadCrumbs[breadCrumbActiveIndex].featuresLinked">

                <template v-for="(featureInfo, index) in feature.featureInfo">
                  <h2 v-bind:key="featureInfo.PresentationID">{{featureInfo.FormName}}</h2>

                  <template  v-if="featureInfo.records.length > 0">

                    <template  v-if="index === 0">

                      <template v-if="detailSettings.FormType === 3">
                        <div class="" v-for="setting in featureInfo.records[0].attributes" :key="setting[0].ID">
                            <template v-for="settingItem in setting">
                                <div v-bind:key="settingItem.ID">
                                      <template v-if="parseInt(settingItem.Type) === 0">
                                        <h2>{{ settingItem.DisplayName }}</h2>
                                        <p>{{settingItem.Prefix}}{{ settingItem.Value }}{{settingItem.Suffix}}</p>
                                      </template>
                                      <template v-else-if="parseInt(settingItem.Type) === 1">
                                        <h2>{{ settingItem.DisplayName }}</h2>
                                        <div class="row">
                                          <div class="col-12">
                                                <img :src="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix" :title="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix">
                                           </div>
                                        </div>
                                      </template>
                                      <template v-if="parseInt(settingItem.Type) === 3">
                                        <h2>
                                          {{ settingItem.DisplayName }}
                                        </h2>
                                        <p>
                                          <a aria-label="Open link in nieuw tabblad" target="_blank" :href="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix">
                                            {{settingItem.Prefix}}{{ activeFeature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}
                                          </a>
                                        </p>
                                      </template>
                                      <template v-if="parseInt(settingItem.Type) === 402">
                                        <h2>{{ settingItem.DisplayName }}</h2>
                                        <p>
                                          {{activeFeature.properties[settingItem.Attribute]}}
                                        </p>
                                        <!--              <a target="_blank" :href="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix">{{settingItem.Prefix}}{{ activeFeature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}</a>-->
                                      </template>
                                </div>
                              </template>
                        </div>
                    </template>
                    <template v-else>

                        <table class="responsive-table" v-bind:key="featureInfo.PresentationID">
                          <tbody>
                            <template v-for="setting in featureInfo.records[0].attributes">
                                <template v-for="settingItem in setting">
                                  <tr v-bind:key="settingItem.ID">
                                    <td class="d-none d-md-table-cell">{{settingItem.DisplayName}}</td>
                                    <td :data-title="settingItem.DisplayName">
                                      <template v-if="parseInt(settingItem.Type) === 0">
                                        {{settingItem.Prefix}} {{settingItem.Value }} {{settingItem.Suffix}}
                                      </template>
                                      <template v-else-if="parseInt(settingItem.Type) === 1">
                                        <template v-if="settingItem.FileStreamed === 1">
                                          <img class="col-md-4" style="display: block" v-if="isImage(settingItem.Suffix, activeFeature.properties[settingItem.Attribute])" v-bind:key="settingItem.ID" :src="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + featureInfo.DomainID + '/presentation/' + featureInfo.PresentationID + '/formattribute/' + settingItem.FormAttributeID + '/item/0/filename/.jpg?fileName=' + settingItem.Value + settingItem.Suffix" :title="settingItem.Prefix + settingItem.Value + settingItem.Suffix">
                                          <a aria-label="Open link in nieuw tabblad" v-if="!isImage(settingItem.Suffix, activeFeature.properties[settingItem.Attribute])" v-bind:key="settingItem.ID" class="open_link" :href="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + featureInfo.DomainID + '/presentation/' + featureInfo.PresentationID + '/formattribute/' + settingItem.FormAttributeID + '/item/0/filename/' + settingItem.Value + settingItem.Suffix + '?fileName=' + settingItem.Value + settingItem.Suffix" target="_blank">
                                            Open link
                                          </a>
                                        </template>
                                        <template v-else>
                                          <img :src="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix" :title="settingItem.Prefix + settingItem.Value + settingItem.Suffix">
                                        </template>
                                      </template>
                                      <template v-else-if="parseInt(settingItem.Type) === 3">
                                        <a aria-label="Open link in nieuw tabblad" target="_blank" :href="settingItem.Prefix + activeFeature.properties[settingItem.Attribute] + settingItem.Suffix">{{settingItem.Prefix}}{{ settingItem.Value }}{{settingItem.Suffix}}</a>
                                      </template>
                                      <template v-else-if="parseInt(settingItem.Type) === 21">
                                        <template v-if="activeFeature.properties.kv.geometry !== undefined">
                                         {{ (activeFeature.properties.kv.geometry.value / 1000).toFixed(1) }} KM
                                        </template>
                                      </template>
                                      <template v-else>
                                        {{settingItem.Prefix}}{{ settingItem.Value }}{{settingItem.Suffix}}
                                      </template>
                                    </td>
                                  </tr>
                                </template>
                              </template>
                          </tbody>
                        </table>
                      </template>
                    </template>

                    <template v-else>
                      <template v-if="featureInfo.records[0].attributes[0][0].Type === 1">
                        <div class="mdb-lightbox row" style="margin-left:-8px" v-bind:key="'body-' + featureInfo.FormID">
                          <figure
                              v-for="(feature, index) in featureInfo.records"
                              :key="index"
                              class="pic col-md-4"
                              @click="() => showImg(index)"
                          >
                            <a  :title="'Bekijk vergrote afbeelding van een ' + activeFeature.properties[settingItem.Attribute]"
                                @click="clickImage" :href="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + featureInfo.DomainID + '/presentation/' + featureInfo.PresentationID + '/formattribute/' + feature.attributes[0][0].FormAttributeID + '/item/' + index + '/filename/.jpg?fileName=' + feature.attributes[0][0].Value + '&_=' + new Date().getTime()"
                                data-size="1600x1067">
                              <img class="img-fluid" :src="kaartViewerUrl + '/admin/rest/geocomponentapi/bookmark/' + settings.BookmarkID + '/domain/' + featureInfo.DomainID + '/presentation/' + featureInfo.PresentationID + '/formattribute/' + feature.attributes[0][0].FormAttributeID + '/item/' + index + '/filename/.jpg?fileName=' + feature.attributes[0][0].Value + '&_=' +  new Date().getTime()" :alt="'Bekijk vergrote afbeelding van een ' + activeFeature.properties[settingItem.Attribute]"/>
                            </a>
                          </figure>
                        </div>
                      </template>
                      <template v-else>
                        <table class="responsive-table" v-bind:key="featureInfo.FormID">
                          <thead>
                          <tr>
                            <th v-for="attributes in featureInfo.records[0].attributes" v-bind:key="attributes.ID">
<!--                              <template v-for="attribute in attributes">-->
                                <span>{{attributes[0].DisplayName}}</span>
<!--                              </template>-->
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="record in featureInfo.records" v-bind:key="record.id">
                            <td v-for="attributes in record.attributes" v-bind:key="attributes.ID">
                              <template v-for="attribute in attributes">
                                <span v-bind:key="attribute.ID">
                                  {{attribute.Value}}
                                </span>
                              </template>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </template>
                    </template>
                  </template>
              </template>
            </template>
          </template>
      </template>
    </template>

    <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></vue-easy-lightbox>

  </div>
</template>

<script>
import DetailMixin from "@/mixins/DetailMixin";
import PopUpMapMixin from "@/mixins/PopUpMapMixin";
import L from "leaflet";
import RouteService from "@/services/route.service";

export default {
  name: "FormDetail",
  mixins: [
    DetailMixin,
    PopUpMapMixin
  ],
  data() {
    return {
      closestAdress: '',
    }
  },
  computed: {
    activeFeature() {
      return this.$store.getters.getActiveFeature
    },
    detailSettings() {
      return this.$store.getters.getDetail
    },
    presentationsLinked() {
      return this.$store.getters.getPresentationsLinked
    },
    settings() {
      return this.$store.getters.getSettings
    },
    kaartViewerUrl() {
      return this.$store.getters.getKaartViewerUrl
    }
  },
  watch: {
    activeFeature() {
      this.loadLinkedPresentations();
    }
  },
  created() {

    this.closestAdress = ''
    this.loadLinkedPresentations();

    this.forms.map((form) => {
      form.detailSettings.records[0].attributes.map( (attributes) => {
        attributes.map((attribute) => {
          if(attribute.Type === 21) {
            const position = L.geoJSON(this.activeFeature).getBounds().getCenter()
            this.closestAdress = 'Adres wordt opgehaald...'

            RouteService.getLocationReversed(this.kaartViewerUrl, position.lng, position.lat).then( (result) => {
              this.closestAdress = result.data.adres.weergavenaam
            }).catch(() => {
              this.closestAdress = 'Geen adres gevonden'
            })
          }
        });
      })
    })

  },
};
</script>


